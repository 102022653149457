<template>
  <v-app>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/store-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  万目云·电商商城
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  精准引流  私域运营  分销裂变  全球卖货<br/>
                  更懂卖货的电商解决方案
                </v-card-title>
                <div class="d-flex justify-center mt-5">
                  <v-btn
                    width="160"
                    class="font-size-20 rounded-pill"
                    color="#0084ff"
                    style="color: #fff"
                    @click="dialog=true"
                  >
                    立即咨询
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- <v-dialog
      v-model="dialog"
      max-width="390"
      height='400'
    >
      <v-card-title class="justify-end pb-0 close-btn" @click.stop="dialog = false">x</v-card-title>
      <v-card-text class="mt-10 font-size-24">联系方式</v-card-text>
      <v-card-text class="font-size-16">孔经理 16619884039</v-card-text>
      <v-img src='../../../public/img/icons/store/weixin.png' class="vimg"></v-img>
    </v-dialog> -->
    <transition name="fade" mode="out-in">
      <div class="dialog" v-show="dialog" @click.stop>
        <div class="">
          <div class="close-btn" @click.stop="dialog = false;">
            <v-img class="icon" src="../../../public/img/icons/menu/close.png"
                   max-width="12" height="12" contain></v-img>
          </div>
          <div class="d-flex justify-center font-size-24 pa-0 mt-6">立即咨询</div>
          <div class="d-flex justify-center mt-10">
            <div>
              <!-- <v-img src="@/assets/login/qrCode-fujingli.jpg" width="200" height="200" contin></v-img> -->
              <v-img src='../../../public/img/icons/store/weixin.png' width="200" height="200" contin></v-img>
              <v-card-text class="text-align-center font-size-14 pa-0 color-666" style="margin-top: -3px">扫一扫加我微信</v-card-text>
            </div>
            <div class="ml-6 mt-2">
              <v-row class="pa-0 align-center">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contacts.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系人</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">孔经理</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-phone.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系电话</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">16619884039</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-email.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">邮箱</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">li.kong@hupomob.com</v-card-text>
                </v-card>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 轮播 -->
    <v-carousel :show-arrows="false" class="warp1 "  hide-delimiter-background
       show-arrows-on-hover
    >
       <v-carousel-item
         v-for="(item,i) in items"
         :key="i"
       >
        <v-sheet>
          <v-row
            class="fill-height d-flex"
            align="center"
            justify="center"
          >
             <div class="box max-width-1200">
              <div class="left" ><img :src='item.img' /></div>  
              <div class="right" >
                <v-card-text class="title" v-text="item.title" />
                <v-card-text class="tip" v-text="item.tip" />
              </div>
            </div>
          </v-row>
        </v-sheet>
       </v-carousel-item>
    </v-carousel>
    <!-- 全流程商家服务 -->
    <div class="second">
      <div class="public-title">
          <p>MERCHANT SERVICES</p>
          <div>
            <span>全流程商家服务</span>
          </div>
        </div>
      <div class="box">
        <v-container>
          <v-row no-gutters justify="center">
            <v-col v-for="(n, ind) in server" :key="ind" cols="12" sm="3">
              <div class="img_title" justify="center">
                <img :src="n.img" />
                <h2>{{n.title}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- 入门示例 -->
    <v-card class=" warp2" :flat="true">
      <div class="public-title">
        <p>INTRODUCTION </p>
        <div>
          <span>入门示例</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs">
        <v-tab v-for="(v, k) in introduction" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in introduction" :key="k"  :transition="false">
          <div class="box">
              <v-img :src='v.img'></v-img>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div class="d-flex justify-center pb-5" style="background-color: #F5F9FD;">
      <v-card class="max-width-1200" width="100%" :flat="true" color="transparent">
        <div class="public-title" style="margin-top: 60px;">
          <p>PARTMER</p>
          <div>
            <span>合作伙伴</span>
          </div>
        </div>
        <v-img class="mt-n5" contain src="https://h5.ophyer.cn/official_website/other/home-hzhb3-2.png"/>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import FanDate from '../../static/FanData.js'
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      tab:null,
      items:FanDate.OnlineRetailersStore.items,
      server:FanDate.OnlineRetailersStore.server,
      introduction:FanDate.OnlineRetailersStore.introduction,
      dialog:false
    }
  },
  created() {
   
  },
  methods: {
   
  }
}
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 470px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}
.warps {
  margin: 0 auto;
  text-align: center;

}
::v-deep .vimg{
  width: 200px;
  margin: 0 auto;
}
::v-deep .v-dialog{
    width: 390px;
    height: 400px !important;
    background: white !important;
    text-align: center !important;
    border-radius: 10px;
}
::v-deep.warp1 {
  height: 366px !important;
  .box {
    // width: 800px;
    // margin: 60px auto 0;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      // width: 500px;
      // height: 300px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    padding-left: 19px;
    //font-family: SourceHanSansCN-Regular;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
      width: 400px;
    }
    .tip {
      margin: 20px 0 35px;
      font-size: 16px !important;
      width: 400px;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
      color: #666;
    }
  }
  .v-item-group{
    margin-top: 20px;
  }
  .fa-circle:before{
    color: #999;
  }
  .v-item--active .fa-circle:before{
    color: #3592FD !important;
  }
  // .fa-circle:before{
  //   color: #3592FD !important;
  // }
  // .fa-circle:after{
  //   color:white !important;
  // }
  .theme--dark.v-btn.v-btn--icon{
    color: #3592FD;
    &:hover{
      color: #3592FD !important;
      opacity: 1 !important;
    }
    &::after{
      color: #3592FD !important;
      opacity: 1 !important;
    }
    &::before{
      color: white !important;
      opacity: 1 !important;
    }
  }
  .v-btn--icon.v-size--small .v-icon{
    font-size: 10px !important;
  }
}
.second {
  .box {
    width: 1300px;
    margin: 0px auto;
    text-align: center;
    justify-content: center;
    //border-bottom: 1px solid;
    h3 {
      margin-bottom: 40px;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 44px;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666;
      line-height: 20px;
      margin-top: 20px;
      
    }
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
    }
    .theme--light.v-image {
      width: 100px;
      margin: 0 auto;
    }
    .img_title {
      width: 266px;
      margin: 0 auto;
      h2 {
        font-size: 20px;
        color: #333;
        // margin-top: 10px;
      }
      p {
        font-size: 14px;
        margin-top: 20px;
        // padding: 0px 45px
      }
    }
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
